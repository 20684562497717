import React from 'react'
import ExploreSlider from '../../components/sliders/explore-slider'

function ExploreClasses() {
  return (
        <>
        <ExploreSlider/>
        </>

  )
}

export default ExploreClasses